import VueRouter from "vue-router";
import auth from "./auth.js";
import Exchange from "@/pages/exchange/index.vue";
import Markets from "@/pages/markets/markets.vue";
import Assets from "@/pages/ucenter/assets.vue";
import Letter from "@/pages/insideLetter/letter.vue";
import Quicklycoins from "@/pages/quicklycoins/quicklycoins.vue";
import AffiliateProgram from "@/pages/affiliate-program/affiliate-program.vue";
import Broker from "@/pages/broker/broker.vue";
// import Withdrawal from "@/pages/assets/recharge_withdrawal.vue";
// import Recharge from "@/pages/assets/recharge_withdrawal.vue";
import Situation from "@/pages/ucenter/situation.vue";
import Security from "@/pages/login/security.vue";
// import Security from "@/pages/ucenter/security-setting.vue";
import Orderbook from "@/pages/exchange/orderbook.vue";
import ContractExchange from "@/pages/contract-exchange/contract-exchange.vue";
import ContractOrderbook from "@/pages/contract-exchange/contract-orderbook.vue";
import StockExchange from "@/pages/stock-exchange/stock-exchange.vue";
import ErrorPage from "@/pages/error-page/index";
import Home from "@/pages/home/home";
import KycNotice from "@/pages/notice/kyc-notice.vue";
import Aboutus from "@/pages/aboutus/aboutus";
import aboutusMaskEx from "@/pages/aboutus/maskEx/aboutus-maskEx.vue";
import brokerProgram from "@/pages/broker-program/broker-program.vue";
import suggest from "@/pages/broker-program/suggest.vue";
import joinAffiliateProgram from "@/pages/broker-program/join-affiliate-program.vue";
import lawEnforcementEnquiry from "@/pages/law-enforcement-enquiry/law-enforcement-enquiry.vue";
import careersMaskEx from "@/pages/aboutus/maskEx/Careers.vue";
import careersDetalis from "@/pages/aboutus/maskEx/careersDetails.vue";
// import GoogleForms from "@/pages/aboutus/maskEx/GoogleForms.vue";
import FormerUniverse from "@/pages/formerUniverse/formerUniverse";
import Finances from "@/pages/finances/index";
import MiningPool from "@/pages/mining-pool/index.vue";
// import Blog from "@/pages/blog/blog.vue";
import BlogDetail from "@/pages/blog/blogDetail.vue";
import Delivery from "@/pages/currency-based-contract/currency-based-contract.vue";
import DeliveryOrderbook from "@/pages/currency-based-contract/currency-based-contract-orderbook.vue";
import ServiceAgreement from "@/pages/service-page/service-agreement.vue";
import PrivacyPolicy from "@/pages/service-page/privacy-policy.vue";
import MaskEXCard from "@/pages/maskexcard/maskexcard.vue";
import HelpCenter from "@/pages/help-center/index.vue";
import MarketsNew from "@/pages/markets_new/markets_new.vue";
import { VUE_UNION_STATION } from "@/assets/js/stationSetting";
// import index from "@/pages/ucenter/index.vue"
// import Login from '@/pages/login/index'

// 用户中心相关路由
import ucenter from "../pages/ucenter/routers";
// import ucenter  from "../pages/ucenter/router/index"
const routes = [
  ucenter,
  {
    path: "/login/:lang?",
    name: "Login",
    meta: {
      title: "登录",
    },
    component: () => import("@/pages/login/index"),
  },
  {
    path: "/landingpage/register/:lang?",
    name: "InviteRegister",
    meta: {
      title: "登录",
    },
    component: () => import("@/pages/login/invite-register-maskex"),
  },

  {
    path: "/forgetPwd/:lang?",
    name: "ForgetPwd",
    meta: {
      title: "忘记密码",
    },
    // component: Login,
    component: () => import("@/pages/login/forgetPwd"),
  },
  {
    path: "/register/:lang?",
    name: "Register",
    meta: {
      title: "注册",
    },
    // component: Login,
    component: () => import("@/pages/login/register"),
  },
  {
    path: "/login/mobile/:lang?",
    name: "LoginMobile",
    meta: {
      title: "登录",
    },
    // component: Login,
    component: () => import("@/pages/login/loginVerification"),
  },
  {
    path: "/login/email/:lang?",
    name: "LoginEmail",
    meta: {
      title: "邮箱登陆",
    },
    // component: Login,
    component: () => import("@/pages/login/loginVerification"),
  },
  {
    path: "/login/verify/:lang?",
    name: "LoginVerify",
    meta: {
      title: "绑定验证",
    },
    component: () => import("@/pages/login/loginFirstBind"),
  },
  //币币交易
  {
    path: "/exchange",
    meta: {
      title: "币币交易",
    },
    component: Exchange,
  },
  {
    path: "/exchange/:symbol/:lang?",
    meta: {
      title: "币币交易",
    },
    component: Exchange,
  },
  //合约交易
  {
    path: "/contract-exchange",
    meta: {
      title: "U本位合约",
    },
    component: ContractExchange,
  },
  {
    path: "/contract-exchange/:symbol/:lang?",
    meta: {
      title: "U本位合约",
    },
    component: ContractExchange,
  },
  //币本位合约交易
  {
    path: "/delivery",
    meta: {
      title: "币本位合约",
    },
    component: Delivery,
  },
  {
    path: "/delivery/:symbol/:lang?",
    meta: {
      title: "币本位合约",
    },
    component: Delivery,
  },
  {
    path: "/delivery-orderbook/:lang?",
    meta: {
      title: "币本位合约盘口",
    },
    component: DeliveryOrderbook,
  },
  //股票交易
  {
    path: "/stock-exchange/:lang?",
    meta: {
      title: "股票交易",
    },
    component: StockExchange,
  },
  {
    path: "/stock-exchange/:symbol/:lang?",
    meta: {
      title: "股票交易",
    },
    component: StockExchange,
  },
  {
    path: "/orderbook/:lang?",
    meta: {
      title: "币币盘口",
    },
    component: Orderbook,
  },
  {
    path: "/contract-orderbook/:lang?",
    meta: {
      title: "合约盘口",
    },
    component: ContractOrderbook,
  },
  //快捷买币
  {
    path: "/quicklycoins/:lang?",
    meta: {
      title: "快捷买币",
    },
    component: Quicklycoins,
  },

  //会员计划
  {
    path: "/affiliate-program/:lang?",
    meta: {
      title: "会员计划",
    },
    component: AffiliateProgram,
  },
  //会员计划
  {
    path: "/broker/:lang?",
    meta: {
      title: "经纪人",
    },
    component: Broker,
  },

  //行情
  {
    path: "/markets/:lang?",
    meta: {
      title: "行情",
    },
    component: Markets,
  },
  //MaskEX Card
  {
    path: "/maskexcard/:lang?/",
    meta: {
      title: "MaskEX Card",
    },
    component: MaskEXCard,
  },
  //行情新版
  {
    path: "/markets-new/:lang?",
    meta: {
      title: "行情",
    },
    component: MarketsNew,
  },
  //资产管理
  {
    path: "/ucenter/assets/:lang?",
    meta: {
      title: "资产管理",
    },
    component: Assets,
  },

  //站内信
  {
    path: "/letter/:lang?",
    meta: {
      title: "站内信",
    },
    component: Letter,
  },

  {
    name: "Security",
    path: "/loginSecurity/:lang?",
    meta: {
      title: "安全项丢失",
    },
    component: Security,
  },
  {
    name: "Finances",
    path: "/finances/:lang?",
    meta: {
      title: "金融理财",
    },
    component: Finances,
  },
  {
    name: "Aboutus",
    path: "/aboutus/:lang?",
    meta: {
      title: "关于我们",
    },
    component: Aboutus,
  },
  {
    name: "aboutusMaskEx",
    path: "/company/aboutus/:lang?",
    meta: {
      title: "关于我们",
    },
    component: aboutusMaskEx,
  },
  {
    name: "brokerProgram",
    path: "/broker-program/:lang?",
    meta: {
      title: "经纪人计划",
    },
    component: brokerProgram,
  },
  {
    name: "feedback",
    path: "/feedback/:lang?",
    meta: {
      title: "投诉与建议",
    },
    component: suggest,
  },
  {
    name: "join-affiliate-program",
    path: "/join-affiliate-program/:lang?",
    meta: {
      title: "加入经纪人计划",
    },
    component: joinAffiliateProgram,
  },
  {
    name: "law-enforcement-enquiry",
    path: "/lawEnforcementEnquiry/:lang?",
    meta: {
      title: "关于我们",
    },
    component: lawEnforcementEnquiry,
  },
  {
    name: "careersMaskEx",
    path: "/company/careers/:lang?",
    meta: {
      title: "招贤纳士",
    },
    component: careersMaskEx,
  },
  {
    name: "careersDetalis",
    path: "/company/careersDetalis/:lang?",
    meta: {
      title: "Job Description",
    },
    component: careersDetalis,
  },
  // {
  //   name: "careets",
  //   path: "/GoogleForms/detalis/:lang?",
  //   meta: {
  //     title: "Google Forms",
  //   },
  //   component: GoogleForms,
  // },
  {
    name: "KycNotice",
    path: "/kyc-notice/:lang?",
    meta: {
      title: "KYC-notice",
    },
    component: KycNotice,
  },
  {
    name: "Metaverse",
    path: "/metaverse/:lang?",
    meta: {
      title: "元宇宙",
    },
    component: FormerUniverse,
  },
  // {
  //   name: "Blog",
  //   path: "/blog/:lang?",
  //   meta: {
  //     title: "Blog",
  //   },
  //   component: Blog,
  // },
  {
    name: "BlogDetail",
    path: "/BlogDetail/:lang?/:pid",
    meta: {
      title: "BlogDetail",
    },
    component: BlogDetail,
  },
  {
    name: "MiningPool",
    path: "/mining-pool/:lang?",
    meta: {
      title: "汇信池",
    },
    component: MiningPool,
  },
  {
    name: "ServiceAgreement",
    path: "/service-agreement/:lang?",
    meta: {
      title: "服务协议",
    },
    component: ServiceAgreement,
  },
  {
    name: "PrivacyPolicy",
    path: "/privacy-policy/:lang?",
    meta: {
      title: "隐私条款",
    },
    component: PrivacyPolicy,
  },
  {
    name: "HelpCenter",
    path: "/help-center/:lang?",
    meta: {
      title: "HelpCenter",
    },
    component: HelpCenter,
  },
  //停用404
  // {
  //   redirect: "/404",
  //   path: "*",
  //   component: ErrorPage,
  //   meta: {
  //     title: "404",
  //   },
  //   children: [
  //     {
  //       path: "/404",
  //       meta: {
  //         title: "404",
  //       },
  //       component: ErrorPage,
  //     },
  //   ],
  // },
  // {
  //   redirect: "/",
  //   path: "*",
  // },

  {
    path: "/",
    meta: {
      title: "首页",
    },
    component: Home,
  },
  {
    path: "/:lang?",
    meta: {
      title: "首页",
    },
    component: Home,
  },
  {
    path: "*",
    redirect: "/",
  },
];

// let langSuffix = localStorage.getItem("language") == "en"
//   ? ["8V"].includes(VUE_UNION_STATION)
//     ? ""
//     : localStorage.getItem("language") ||
//       (["MaskEx", "S6", "B9", "bitda"].includes(VUE_UNION_STATION)
//         ? "en"
//         : "zh_CN")
//   : localStorage.getItem("language") ||
//     (["MaskEx", "S6", "B9", "bitda"].includes(VUE_UNION_STATION)
//       ? "en"
//       : "zh_CN");
const router = new VueRouter({
  mode: "history",
  routes,
});
auth(router);

const VueRouterPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(to) {
  return VueRouterPush.call(this, to).catch(err => err);
};

export default router;
